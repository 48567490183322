import { Pause, Play } from '@aether/icons'
import { styled } from '@aether/styles'
import { MouseEventHandler, FC } from 'react'

type VideoControlButtonProps = {
  isPlaying: boolean
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
}
const ControlButton = styled('button', {
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '$32',
  height: '$32',
  background: 'transparent',
  zIndex: '$videoControls',
  borderRadius: '$rMax',

  '&:hover': {
    cursor: 'pointer',
  },

  $focusStyle: 'default',
})

export const VideoControlButton: FC<VideoControlButtonProps> = ({
  isPlaying,
  onRequestPause,
  onRequestPlay,
}) => {
  return isPlaying ? (
    <ControlButton onClick={onRequestPause} aria-label="pause">
      <Pause />
    </ControlButton>
  ) : (
    <ControlButton onClick={onRequestPlay} aria-label="play">
      <Play />
    </ControlButton>
  )
}
