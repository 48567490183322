import { Discount } from './types'

export const findHighestDiscountPercentage = (
  discounts?: Discount[],
  productCollections?: string[],
  isVipUser?: boolean,
): Discount | undefined => {
  if (!discounts || !productCollections) return
  if (!isVipUser) {
    discounts = discounts.filter(
      (discount) => discount.discountVisibility === 'public',
    )
  }

  const applicableDiscounts = discounts.filter((discount) => {
    return discount.collections?.some((collection) =>
      productCollections.includes(collection),
    )
  })

  if (!applicableDiscounts.length) return

  return applicableDiscounts.sort((a, b) => {
    if (!a.percentage || !b.percentage) return 1
    return b.percentage - a.percentage
  })[0]
}

export const findTieredDiscounts = (
  discounts?: Discount[],
  isVipUser?: boolean,
) => {
  if (!discounts) return
  if (!isVipUser) {
    discounts = discounts.filter(
      (discount) => discount.discountVisibility === 'public',
    )
  }

  return discounts.filter((discount) => discount.type === 'tiered')
}
