import React, { ComponentPropsWithRef } from 'react'
import { styled } from '@aether/styles'
import { ModalOverlayStyle } from './Modal'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'

const Backdrop = styled('div', {
  opacity: 0,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  variants: {
    overlayVariant: {
      transparent: {},
      blur: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(32px)',
      },
      light: {},
      dark: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
      darker: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
  },
})

const OverlayStyled = styled('div', {
  position: 'fixed',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gridGap: '$24',
  overflow: 'auto',
  zIndex: 9999,
  variants: {
    hasPadding: {
      true: {
        padding: '$24',
      },
    },
  },
  [`&.base ${Backdrop}`]: { opacity: 0 },
  [`&.after-open ${Backdrop}`]: { opacity: 1 },
  [`&.before-close ${Backdrop}`]: { opacity: 0 },
})

const ButtonWrapper = styled('div', {
  margin: '0 auto',
})

export type OverlayProps = ComponentPropsWithRef<'div'> & {
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  hasPadding?: boolean
  openTime: number
  closeTime: number
}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      overlayCloseButton,
      overlayCloseButtonLabel,
      openTime,
      closeTime,
      children,
      hasPadding,
      overlayVariant,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation('modal')
    return (
      <OverlayStyled
        {...restProps}
        style={{}}
        css={{
          [`&.base ${Backdrop}`]: {
            transition: `opacity ${openTime}ms`,
          },
          [`&.before-close ${Backdrop}`]: {
            transition: `opacity ${closeTime}ms`,
          },
        }}
        ref={ref}
        hasPadding={hasPadding}
      >
        <Backdrop overlayVariant={overlayVariant} />
        {children}
        {overlayCloseButton && (
          <ButtonWrapper>
            <Button size={'M'}>{overlayCloseButtonLabel ?? t('close')}</Button>
          </ButtonWrapper>
        )}
      </OverlayStyled>
    )
  },
)
Overlay.displayName = 'Overlay'
