import { styled, keyframes } from '@stitches/react'
import { FC, forwardRef } from 'react'

const OuterGroup: FC<{ className?: string }> = ({ className }) => (
  <g className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1966 4.82338C30.0983 2.16764 31.9671 0.102066 34.6229 0.00370561C37.2786 -0.0946551 39.2458 1.7742 39.3442 4.5283C39.4425 7.18403 37.5737 9.24961 34.9179 9.34797C32.2622 9.34797 30.295 7.47912 30.1966 4.82338ZM37.7704 4.5283C37.672 2.65944 36.4917 1.28239 34.7212 1.38075C32.9507 1.47912 31.7704 2.85616 31.8688 4.72502C31.9671 6.59387 33.1474 7.97092 34.9179 7.87256C36.6884 7.87256 37.8688 6.39715 37.7704 4.5283Z"
      fill="black"
    />
    <path
      d="M25.672 11.1185L24.295 7.67583L19.1802 4.03648L20.8524 3.34796L24.4917 6.10206L25.4753 1.67583L27.1474 0.987305L25.7704 7.08566L27.1474 10.5283L25.672 11.1185Z"
      fill="black"
    />
    <path
      d="M43.8688 0.790527L42.0983 5.90528C41.3114 8.06922 42.0983 9.83971 44.4589 10.6266C47.0163 11.5118 48.6884 10.4298 49.377 8.3643L51.1474 3.24955L49.672 2.75774L47.9999 7.8725C47.5081 9.15119 46.4261 9.83971 45.0491 9.34791C43.672 8.8561 43.1802 7.77414 43.672 6.39709L45.3442 1.28233L43.8688 0.790527Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.2457 12.4956C59.3113 10.8235 62.0654 11.0202 63.7375 13.0858C65.4097 15.1513 65.1146 17.9054 63.049 19.5776C60.9834 21.2497 58.2293 21.053 56.5572 18.9874C54.8851 16.9218 55.1801 14.1677 57.2457 12.4956ZM62.0654 18.2989C63.5408 17.1186 63.8359 15.3481 62.6556 13.971C61.4752 12.594 59.7047 12.594 58.2293 13.7743C56.8523 14.9546 56.4588 16.7251 57.6392 18.1022C58.8195 19.4792 60.6883 19.4792 62.0654 18.2989Z"
      fill="black"
    />
    <path
      d="M66.787 23.1185L62.2624 29.6103L62.6558 31.0857L71.1149 28.1349L70.6231 26.6594L64.7214 28.725L69.1477 22.3316L68.6558 20.7578L60.1968 23.7086L60.6886 25.184L66.787 23.1185Z"
      fill="black"
    />
    <path
      d="M62.8525 32.856L71.8033 32.6592V34.233L64.2295 34.4297L64.3279 38.3642H62.9508L62.8525 32.856Z"
      fill="black"
    />
    <path
      d="M66.1966 41.5121L62.5573 40.7252L62.2622 42.299L65.9015 42.9875L70.2294 47.5121L70.6229 45.7416L67.377 42.5941L71.6065 40.9219L71.9999 39.1514L66.1966 41.5121Z"
      fill="black"
    />
    <path
      d="M62.0658 54.2006C64.1313 56.0695 64.033 58.9219 62.3609 60.7908C61.1805 62.0695 59.8035 62.3646 58.5248 62.1678L58.8199 60.6924C59.6068 60.7908 60.5904 60.4957 61.2789 59.8072C62.4592 58.5285 62.3609 56.6597 60.9838 55.2826C59.6068 54.0039 57.7379 54.1023 56.4592 55.381C55.8691 56.0695 55.574 56.9547 55.574 57.5449L56.5576 58.5285L58.3281 56.5613L59.3117 57.5449L56.4592 60.6924L54.0002 58.4301C53.9018 57.1515 54.1969 55.7744 55.2789 54.5941C57.2461 52.6269 60.0002 52.3318 62.0658 54.2006Z"
      fill="black"
    />
    <path
      d="M56.1641 66.1023L51.7379 58.2334L46.3281 61.2826L47.0166 62.4629L51.0494 60.2006L52.2297 62.3646L48.2953 64.5285L48.9838 65.7088L52.9182 63.5449L54.0986 65.6105L50.0658 67.8728L50.7543 69.1515L56.1641 66.1023Z"
      fill="black"
    />
    <path
      d="M42.6886 62.2661L44.3608 69.6432L47.0165 69.053L47.3116 70.43L40.4263 72.0038L40.1313 70.6268L42.787 70.0366L41.1149 62.6596L42.6886 62.2661Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8688 70.7252C28.4261 71.5121 30.7868 70.1351 31.5737 67.5777C32.2622 65.0203 30.9835 62.6597 28.4261 61.8728C25.8688 61.0859 23.5081 62.4629 22.7212 65.0203C22.0327 67.5777 23.3114 69.9383 25.8688 70.7252ZM26.2622 69.4465C24.4917 68.9547 23.8032 67.2826 24.295 65.5121C24.7868 63.7416 26.2622 62.758 28.0327 63.2498C29.8032 63.7416 30.4917 65.4138 29.9999 67.1842C29.5081 68.9547 28.0327 69.9383 26.2622 69.4465Z"
      fill="black"
    />
    <path
      d="M17.8031 56.1677L17.7047 64.1349L21.5408 59.0202L22.8195 60.0038L17.4096 67.1841L16.1309 66.2005L16.2293 58.43L12.4916 63.3481L11.2129 62.3645L16.6228 55.1841L17.8031 56.1677Z"
      fill="black"
    />
    <path
      d="M8.06539 58.9219L15.4424 53.8072L11.9014 48.8892L10.7211 49.676L13.2785 53.4137L11.2129 54.7908L8.65556 51.0531L7.47523 51.84L10.0326 55.5777L8.16376 56.8564L5.60638 53.1187L4.52441 53.8072L8.06539 58.9219Z"
      fill="black"
    />
    <path
      d="M8.26246 42.6922L6.88541 42.0037C7.47557 41.3152 7.96737 40.3316 7.86901 39.0529C7.77065 37.7742 7.08213 37.3808 6.49196 37.3808C5.68618 37.4656 5.53868 38.4647 5.37142 39.5975C5.15076 41.0921 4.89573 42.8195 3.04934 42.9873C1.57393 43.184 0.295242 42.0037 0.0985208 40.0365C0.000160068 38.6594 0.295242 37.4791 0.983767 36.5939L2.26246 37.3808C1.67229 38.1676 1.37721 39.1512 1.47557 40.1349C1.57393 41.0201 2.06573 41.6103 2.75426 41.5119C3.44599 41.4306 3.58224 40.5076 3.74097 39.4323C3.96594 37.9083 4.23607 36.0784 6.19688 35.9053C7.67229 35.7086 9.04934 36.6922 9.34442 39.0529C9.44278 40.5283 8.95098 41.807 8.26246 42.6922Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 32.3643L8.85246 34.0364L9.14754 32.4626L5.90164 31.8725L6.29508 29.5118C6.68853 27.643 5.60656 26.3643 4.13115 26.0692C2.55738 25.7741 1.18033 26.561 0.786885 28.4298L0 32.3643ZM2.16393 28.9216C2.2623 28.0364 2.95082 27.5446 3.83607 27.643C4.62295 27.8397 5.11475 28.6266 4.91803 29.4134L4.52459 31.6757L1.77049 31.0856L2.16393 28.9216Z"
      fill="black"
    />
    <path
      d="M10.918 25.9707L2.65576 22.3314L3.24592 20.856L11.5082 24.4953L10.918 25.9707Z"
      fill="black"
    />
    <path
      d="M8.16371 17.315L16.1309 17.6101L17.1146 16.3314L10.0326 10.8232L9.04896 12.1019L13.967 15.9379L6.1965 15.7412L5.21289 17.0199L12.2949 22.5281L13.2785 21.2494L8.16371 17.315Z"
      fill="black"
    />
  </g>
)

const InnerGroup: FC<{ className?: string }> = ({ className }) => (
  <g className={className}>
    <path
      d="M35.4097 18.397L44.1638 38.4626C43.8688 38.561 42.5901 38.8561 42.5901 38.8561L35.5081 22.5282L31.377 32.0692C31.1802 32.0692 29.5081 32.2659 29.5081 32.2659L35.4097 18.397Z"
      fill="black"
    />
    <path
      d="M46.9179 48.7905L44.3606 42.9872C44.3606 42.9872 45.836 42.5938 46.0327 42.5938L48.3933 48.102L46.9179 48.7905Z"
      fill="black"
    />
    <path
      d="M22.7212 48.102L27.4425 36.9872C28.4261 36.7905 29.4097 36.6921 29.4097 36.6921L24.1966 48.7905L22.7212 48.102Z"
      fill="black"
    />
    <path
      d="M48.3935 37.8727C48.3935 37.2825 47.1148 36.4956 44.5574 35.9054L43.6721 34.135C47.3115 34.8235 49.9672 36.1022 49.9672 37.971C49.9672 41.0202 42.2951 42.4956 35.2131 42.4956C32.9508 42.4956 30.6885 42.2989 28.5246 42.0038L29.2131 40.43C30.9836 40.7251 33.0492 40.8235 35.3115 40.8235C43.7705 40.8235 48.3935 38.8563 48.3935 37.8727Z"
      fill="black"
    />
    <path
      d="M35.2131 33.3481C36.3934 33.3481 37.5738 33.3481 38.6557 33.4464L39.2459 35.2169C37.9672 35.1186 36.5902 35.0202 35.1148 35.0202C26.6557 35.0202 22.0328 36.9874 22.0328 37.971C22.0328 38.4628 23.0164 39.053 24.7869 39.6432L24.0984 41.1186C21.9344 40.43 20.459 39.3481 20.459 37.971C20.459 34.8235 28.1311 33.3481 35.2131 33.3481Z"
      fill="black"
    />
  </g>
)

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
})

const StyledOuterGroup = styled(OuterGroup, {
  transformOrigin: 'center',
  variants: {
    animate: {
      true: {
        animation: `${rotate} 2500ms cubic-bezier(0.25, 0.52, 0, 1.03) 250ms`,
      },
    },
  },
})

type Props = { className?: string; animate: boolean }

export const AnimatedLogo = forwardRef<SVGSVGElement, Props>(
  ({ className, animate }, ref) => {
    return (
      <svg
        ref={ref}
        className={className}
        width="72"
        height="73"
        viewBox="0 0 72 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <InnerGroup />
        <StyledOuterGroup animate={animate} />
      </svg>
    )
  },
)
