import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { sizes } from './sizes'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { lin } from './lin'
import { getFontStyles } from './fonts'
import { getContainerSize } from './containerSize'
import { getContainerSpace } from './containerSpace'
import { getFocusStyles } from './focusStyles'

const stitches = createStitches({
  theme: {
    colors,
    /**
     * Custom radius values
     * example:
     * 'r1': '6px',
     *  -> borderRadius: '$r1' // maps to theme.radii.1
     */
    radii: {
      r1: '25px',
      r2: '5px',
      rMax: '999px',
    },
    space: sizes,
    sizes,
    zIndices: {
      header: 100,
      progressHeader: 110,
      journalHeader: 90,
      journalLink: 85,
      content: 80,
      returnsBanner: 100,
      videoControls: 90,
      plpSubNavigation: 90,
      stickyButton: 120,
    },
  },
  media: breakpoints,
  /**
   * Custom utilities
   * https://stitches.dev/docs/utils
   * example:
   * m: (value: string) => ({ margin: value }),
   */
  utils: {
    $containerSpace: getContainerSpace,
    $containerSize: getContainerSize,
    $aetherFont: getFontStyles,
    $lin: lin,
    $focusStyle: getFocusStyles,

    $visuallyHidden: (shouldApply: boolean) => {
      if (shouldApply) {
        return {
          position: 'absolute',
          overflow: 'hidden',
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          width: '1px',
          height: '1px',
          whiteSpace: 'nowrap',
        }
      }
      return {}
    },
    $posterHeight: (shouldApply: boolean) => {
      if (shouldApply) {
        return {
          minHeight:
            'calc(100vh - $headerHeightNormal - $notificationHeaderHeight)',
          '@lg': {
            minHeight:
              'calc(100vh - $headerHeightLarge - $notificationHeaderHeight)',
          },
        }
      }
      return {}
    },
  },
})

export type CSS = Stitches.CSS<typeof stitches.config>

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = stitches
