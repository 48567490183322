import React, { FC } from 'react'

type Props = {
  className?: string
  size?: 'default' | 'small'
}

export const Close: FC<Props> = ({ className, size = 'default' }) => {
  const [width, height] = (() => {
    switch (size) {
      case 'small':
        return [10, 10]
      default:
        return [20, 20]
    }
  })()

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0398 10.1701L18.7498 2.4601C18.9498 2.2601 18.9498 1.9501 18.7498 1.7501C18.5498 1.5501 18.2398 1.5501 18.0398 1.7501L10.3298 9.4601L2.61977 1.7501C2.41977 1.5501 2.10977 1.5501 1.90977 1.7501C1.70977 1.9501 1.70977 2.2601 1.90977 2.4601L9.61977 10.1701L1.90977 17.8801C1.70977 18.0801 1.70977 18.3901 1.90977 18.5901C2.00977 18.6901 2.13977 18.7401 2.25977 18.7401C2.37977 18.7401 2.51977 18.6901 2.60977 18.5901L10.3198 10.8801L18.0298 18.5901C18.1298 18.6901 18.2598 18.7401 18.3798 18.7401C18.4998 18.7401 18.6398 18.6901 18.7298 18.5901C18.9298 18.3901 18.9298 18.0801 18.7298 17.8801L11.0198 10.1701H11.0398Z"
        fill="black"
      />
    </svg>
  )
}
