import { SiteConfiguration } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { Link } from '@aether/components'

type Props = {
  linkGroups?: SiteConfiguration['footer']['linkGroups']
  className?: string
}

const Root = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  width: '100%',
})

const Col = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

const ColHeading = styled('span', {
  $aetherFont: 'heading06',
  color: '$black',
  background: 'none',
  textAlign: 'left',
  padding: '0 0 $32 0',
})

const ColItems = styled('ul', {
  display: 'grid',
  alignItems: 'self-end',
})

const LinkWrap = styled('li', {
  $aetherFont: 'ui03',
  variants: {
    isLastInGroup: {
      true: {
        padding: '0 0 $36 0',
      },
      false: {
        padding: '0 0 $20 0',
      },
    },
  },
})

const StyledLink = styled(Link, {
  $aetherFont: 'ui03',
})

export const DesktopNavigation: FC<Props> = ({ linkGroups, className }) => {
  return (
    <Root className={className}>
      {linkGroups?.map(({ links, title }) => (
        <Col key={title}>
          <ColHeading>{title}</ColHeading>
          <ColItems>
            {links?.map(({ type, payload }, index) => {
              if (type === 'link') {
                const link = payload?.link || {}
                const label = payload?.label || ''
                const isLastInGroup = links[index + 1]?.type === 'divider'
                return (
                  <LinkWrap
                    isLastInGroup={isLastInGroup}
                    key={`${label}-${index}`}
                  >
                    <StyledLink {...link} key={label} appearance="defaultLink">
                      {label}
                    </StyledLink>
                  </LinkWrap>
                )
              }
              return null
            })}
          </ColItems>
        </Col>
      ))}
    </Root>
  )
}
