import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Discount,
  DiscountsProviderProps,
  IDiscountsProvider,
  IDiscountsProviderProps,
} from './types'
import { findHighestDiscountPercentage, findTieredDiscounts } from './utils'
import { useRouter } from 'next/router'

export const DiscountsContext = createContext<IDiscountsProvider>({})

export const DiscountsProvider = ({
  allDiscounts,
  children,
}: IDiscountsProviderProps): ReactElement => {
  return (
    <DiscountsContext.Provider value={{ allDiscounts }}>
      {children}
    </DiscountsContext.Provider>
  )
}

export const useDiscounts = ({
  collections,
  userRole = 'customer',
}: DiscountsProviderProps): IDiscountsProvider => {
  const router = useRouter()
  const path = router.asPath
  const context = useContext(DiscountsContext)
  const [matchedPercentageDiscount, setMatchedPercentageDiscount] =
    useState<Discount>()
  const [tieredDiscounts, setTieredDiscounts] = useState<Discount[]>()
  const isVipUser = userRole === 'vip'

  const getTieredDiscounts = (isVipUser: boolean): Discount[] | undefined => {
    return findTieredDiscounts(context.allDiscounts, isVipUser)
  }
  const getApplicablePercentageDiscount = (isVipUser: boolean) => {
    if (!collections) return
    return findHighestDiscountPercentage(
      context.allDiscounts,
      collections,
      isVipUser,
    )
  }

  useEffect(() => {
    setMatchedPercentageDiscount(getApplicablePercentageDiscount(isVipUser))
  }, [collections, context.allDiscounts, isVipUser, path])

  useEffect(() => {
    setTieredDiscounts(getTieredDiscounts(isVipUser))
  }, [context.allDiscounts, isVipUser, path])

  return {
    allDiscounts: context.allDiscounts,
    matchedDiscount: matchedPercentageDiscount,
    tieredDiscounts,
  }
}
