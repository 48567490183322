/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  white: '#FFFFFF',
  white_light: 'rgba(255, 255, 255, 0.1)',
  black: '#000000',
  gray_light: '#F5F5F5',
  gray_light_second: '#C4C4C4',
  gray_light_third: '#EDEDED',
  gray_light_fourth: '#CDCDCD',
  gray_medium: '#757575',
  blue: '#0C68C3',
  orange: '#DFB10E',
  red: '#C82A1D',
  beige: '#EEECE4',
}

export type Color = keyof typeof colors
