import { LocaleId } from '..'
import { supportedLanguagesIds } from './locales'
import regionsJSON from './regions.json'

export type ShopifyInstanceId = 'GLOBAL'

export interface Region {
  id: string
  isDefault?: boolean
  currencyCode: string
  currencySymbol: string
  label: string
  shortLabel: string
  flag: string
  defaultLocale: LocaleId
  supportedLocales: typeof supportedLanguagesIds
}

export const DEFAULT_REGION_ID = 'US'

export const regions = regionsJSON as Region[]

export const supportedRegionsIds = regions.map((region) => region.id)

export type RegionId = typeof supportedRegionsIds[number]

export const isSupportedRegion = (str: string): str is RegionId => {
  return supportedRegionsIds.includes(str as RegionId)
}

export const isSupportedRegionLocale = (
  regionId: RegionId,
  localeId: LocaleId,
): boolean => {
  const supportedLocales = regions.find(
    ({ id }) => id === regionId,
  )?.supportedLocales

  return Boolean(supportedLocales?.includes(localeId))
}

export const normalizedRegions = regions.reduce<Record<RegionId, Region>>(
  (acc, locale) => {
    return {
      ...acc,
      [locale.id]: locale,
    }
  },
  {} as Record<typeof supportedRegionsIds[number], Region>,
)
