import { styled } from '@aether/styles'
import { FC } from 'react'

const StyledSwitch = styled('div', {
  display: 'inline-grid',
  gridAutoFlow: 'column',
  border: '1px solid $black',
  borderRadius: '$r1',
  gap: '2px',
  padding: '3px',
})

const StyledRadioButton = styled('div', {
  position: 'relative',
  $aetherFont: 'ui06',
  borderRadius: '$r1',
  transition: 'background-color 0.3s, color 0.3s',
  padding: '$8 18px',
  variants: {
    isChecked: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
      false: {
        backgroundColor: 'transparent',
      },
    },
  },
})

const StyledRadioInput = styled('input', {
  display: 'block',
  margin: 0,
  position: 'absolute',
  inset: 0,
  opacity: '0',
  zIndex: '100',
  cursor: 'pointer',
})

const StyledRadioLabel = styled('label', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  inset: 0,
  zIndex: '90',
})

type Props = {
  items: { value: string; label: string }[]
  value: string
  onChange: (newValue: string) => void
}

export const RadioSwitch: FC<Props> = ({ items, value, onChange }) => {
  return (
    <StyledSwitch role="radiogroup" aria-label="Measurement units">
      {items.map(({ label, value: itemValue }) => {
        return (
          <StyledRadioButton
            key={itemValue}
            isChecked={value === itemValue}
            role="radio"
            aria-selected={value === itemValue}
          >
            <StyledRadioInput
              type="radio"
              id={`radio-${itemValue}`}
              name={`radio-${itemValue}`}
              value={itemValue}
              checked={value === itemValue}
              onChange={() => {
                onChange(itemValue)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onChange(itemValue)
                }
              }}
            />
            <StyledRadioLabel htmlFor={`radio-${itemValue}`}>
              {label}
            </StyledRadioLabel>
          </StyledRadioButton>
        )
      })}
    </StyledSwitch>
  )
}
