import React, { FC, useEffect } from 'react'
import { useCartContext } from '../useCartContext'
import { CloseCircle } from '@aether/icons'

import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { ProductColorType, SiteConfiguration } from '@aether/models'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { useDiscounts } from '@aether/discounts/utils-context'

import { SubTotalSummary } from './SubTotalSummary'
import { DiscountIndicator } from './DiscountIndicator'
import { useCheckout } from '../useCheckout'
import { StyledClickable, Link, Loader, CartLine } from '@aether/components'
import { cartAnalytics } from '../helpers/cartAnalytics'
import {
  TieredDiscount,
  TieredDiscountIndicator,
} from './TieredDiscountIndicator'

const CloseButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  justifySelf: 'end',
  padding: 0,
  $focusStyle: 'default',
  height: 44,
  width: 44,
  margin: '-11px',
})

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
})

const Overlay = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray_light',
  zIndex: `$content`,
  opacity: 0.5,
})

const HeaderWrap = styled('div', {
  display: 'grid',
  padding: '$20 0 0 0',
  borderTop: '1px solid $black',
  backgroundColor: '$white',
})

const ContentWrap = styled('div', {
  display: 'grid',
  height: '100%',
  overflow: 'scroll',
  flex: 1,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const FooterWrap = styled('div', {
  borderTop: '1px solid $black',
  padding: '$20 0 $24 0',
  display: 'grid',
  alignContent: 'start',
  backgroundColor: '$white',
})

const TitleWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto 1fr',
  alignItems: 'center',
  gap: '20px',
  paddingBottom: '$24',
})

const CartTitle = styled('h1', {
  $aetherFont: 'heading03',
  textAlign: 'center',
})

const LineAmountCounter = styled('span', {
  margin: '2px 0 0 0',
  $aetherFont: 'ui05',
  border: '1px solid $black',
  borderRadius: '$r1',
  padding: '2px 8px',
})

const LineItemsList = styled('ul', {
  display: 'grid',
  gridRowGap: '$36',
  alignContent: 'start',
  padding: '0 0 $36 0',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  variants: {
    isScrollable: {
      true: {
        overflowY: 'scroll',
      },
    },
  },
})

const EmptyCartLinksList = styled('ul', {
  display: 'grid',
  gridRowGap: '$16',
  width: '100%',
  justifyItems: 'center',
  alignContent: 'start',
  // to keep offset of buttons in container
  padding: '3px',
})

const EmptyCartLink = styled(Link, {
  width: '100%',
})

const StyledDiscountIndicator = styled(DiscountIndicator, {
  paddingBottom: '$36',
})

const StyledTieredDiscountIndicator = styled(TieredDiscountIndicator, {
  paddingBottom: '$36',
})

const StyledSubTotalSummary = styled(SubTotalSummary, {
  paddingBottom: '$16',
})

const EmptyCartLinkWrap = styled('li', {
  width: '100%',
  maxWidth: '400px',
})

const SaleLabel = styled('div', {
  marginBottom: '$12',
  $aetherFont: 'ui05',
  variants: {
    color: {
      black: {
        color: '$black',
      },
      red: {
        color: '$red',
      },
    },
  },
  defaultVariants: {
    color: 'black',
  },
})

export const MiniCart: FC<{
  cartConfig: SiteConfiguration['cart']
  colorsMap: ProductColorType[]
  showCloseButton?: boolean
  isListScrollable?: boolean
  className?: string
}> = ({
  colorsMap,
  cartConfig,
  showCloseButton = false,
  className,
  isListScrollable = true,
}) => {
  const {
    cart,
    quantity,
    setCartModalOpen,
    updateCartLines,
    removeCartLines,
    processing,
  } = useCartContext()
  const { t } = useTranslation('cart')
  const { handleCheckout, isCheckoutLoading } = useCheckout()
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const emptyCartLinks = cartConfig?.emptyCartLinks || []

  const discountTiersLabel = cartConfig?.discountLabel
  const discountTiers = cartConfig?.discounts
  const nonDiscountedProductTypes = cartConfig?.nonDiscountedProductTypes
  const nonDiscountedVendors = cartConfig?.nonDiscountedVendors
  const enablePromotion = cartConfig?.enablePromotion

  const { tieredDiscounts } = useDiscounts({ collections: [], userRole })
  const hasTieredDiscount = tieredDiscounts && tieredDiscounts.length > 0

  const isCartEmpty = quantity === 0

  useEffect(() => {
    if (!cart) {
      return
    }
    cartAnalytics.viewCart(cart)
  }, [cart])

  return (
    <Root className={className}>
      {processing && (
        <Overlay>
          <Loader />
        </Overlay>
      )}

      <HeaderWrap>
        <TitleWrap>
          <CartTitle>{t('cart')}</CartTitle>
          <LineAmountCounter>
            {t('quantity', { value: quantity })}
          </LineAmountCounter>
          {showCloseButton && (
            <CloseButton
              onClick={() => setCartModalOpen(false)}
              aria-label="Close"
            >
              <CloseCircle />
            </CloseButton>
          )}
        </TitleWrap>

        {/**
         * ideally the Tiered discounts indicator below should be unified
         * as per https://github.com/commerce-ui/aether/issues/228
         * */}
        {!isCartEmpty && !isVip && enablePromotion && (
          <StyledDiscountIndicator
            cart={cart}
            discountLabel={discountTiersLabel}
            discounts={discountTiers}
          />
        )}
        {!isCartEmpty && hasTieredDiscount && (
          <StyledTieredDiscountIndicator
            cart={cart}
            discounts={tieredDiscounts as TieredDiscount[]}
          />
        )}
        {cartConfig.saleLabel && (
          <SaleLabel color={cartConfig.saleLabelColor}>
            {cartConfig.saleLabel}
          </SaleLabel>
        )}
      </HeaderWrap>
      <ContentWrap>
        {!isCartEmpty ? (
          <LineItemsList isScrollable={isListScrollable}>
            {cart?.lines?.map((line) => (
              <li key={line.id}>
                <CartLine
                  line={line}
                  colorsMap={colorsMap}
                  removeCartLines={removeCartLines}
                  updateCartLines={updateCartLines}
                  setCartModalOpen={setCartModalOpen}
                  isCartDiscountApplied={enablePromotion || hasTieredDiscount}
                  nonDiscountedProductTypes={nonDiscountedProductTypes}
                  nonDiscountedVendors={nonDiscountedVendors}
                />
              </li>
            ))}
          </LineItemsList>
        ) : (
          <EmptyCartLinksList>
            {emptyCartLinks.map(({ label, link }) => {
              return (
                <EmptyCartLinkWrap key={label}>
                  <EmptyCartLink
                    {...link}
                    appearance={'badgeBlack'}
                    onClick={() => setCartModalOpen(false)}
                  >
                    {label}
                  </EmptyCartLink>
                </EmptyCartLinkWrap>
              )
            })}
          </EmptyCartLinksList>
        )}
      </ContentWrap>
      {!isCartEmpty ? (
        <FooterWrap>
          <StyledSubTotalSummary cart={cart} />
          <StyledClickable
            appearance={'badgeBlack'}
            onClick={handleCheckout}
            disabled={isCheckoutLoading}
          >
            {isCheckoutLoading ? <Loader /> : t('checkout')}
          </StyledClickable>
        </FooterWrap>
      ) : null}
    </Root>
  )
}
