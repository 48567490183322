import { FC } from 'react'
import { ShopifyProduct } from '@aether/models'
import { PriceRange } from '../components/PriceRange/PriceRange'
import { calculateCompareAtPriceRange } from './calculateCompareAtPriceRange'
import { Discount } from '@aether/discounts/utils-context'
import { calculatePriceRange } from './calculatePriceRange'
import {
  PromoPriceWrapper,
  PromoPriceWrapperProps,
} from '../PromoPriceWrapper/PromoPriceWrapper'

export const ProductPrice: FC<{
  product?: ShopifyProduct
  discount?: Discount
  promoPriceColor?: PromoPriceWrapperProps
}> = ({ product, discount, promoPriceColor = 'red' }) => {
  if (!product || !product.priceRange) {
    return null
  }

  const compareAtPriceRange = calculateCompareAtPriceRange(product, discount)
  const priceRange = calculatePriceRange(product, discount)

  const isPriceRangeIdentical =
    priceRange.minVariantPrice.amount === priceRange.maxVariantPrice.amount
  const isCompareAtPriceRangeIdentical =
    compareAtPriceRange?.minVariantPrice.amount ===
    compareAtPriceRange?.maxVariantPrice.amount
  const showCompareAtPriceRange =
    compareAtPriceRange &&
    isPriceRangeIdentical &&
    isCompareAtPriceRangeIdentical &&
    parseFloat(compareAtPriceRange.minVariantPrice.amount) !== 0

  return showCompareAtPriceRange ? (
    <>
      <PromoPriceWrapper color={promoPriceColor}>
        <PriceRange range={priceRange} />
      </PromoPriceWrapper>
      &nbsp;
      <s data-testid="compare-at-price">
        <PriceRange range={compareAtPriceRange} />
      </s>
    </>
  ) : (
    <PriceRange range={priceRange} />
  )
}
